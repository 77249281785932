<template>
  <v-dialog
    v-model="modal"
    width="320"
    @keydown.enter="handleConfirm"
    @keydown.esc="handleCancel"
  >
    <template
      v-slot:activator="{ on }"
      inline-block
    >
      <v-row>
        <v-col
          class="py-0"
          cols="6"
        >
          <v-text-field
            :value="getValue(0)"
            :label="getValue(0) === '' ? $t('ui.select_date_start') : null"
            hide-details="auto"
            append-icon="event"
            outlined
            readonly
            dense
            v-on="on"
            @click:append="modal = true"
          />
        </v-col>
        <v-col
          class="py-0"
          cols="6"
        >
          <v-text-field
            :value="getValue(1)"
            :label="getValue(1) === '' ? $t('ui.select_date_end') : null"
            hide-details="auto"
            append-icon="event"
            outlined
            readonly
            dense
            v-on="on"
            @click:append="modal = true"
          />
        </v-col>
      </v-row>
    </template>
    <v-date-picker
      :value="currentValue"
      :locale="currentLocale"
      :selected-items-text="currentTitle"
      full-width
      range
      @input="handleInput"
    >
      <v-btn
        v-t="'ui.reset'"
        :disabled="currentValueIsEmpty"
        text
        small
        @click="handleReset"
      />
      <v-spacer />
      <v-btn
        v-t="'ui.close'"
        text
        @click="handleCancel"
      />
      <v-btn
        v-t="'ok'"
        :disabled="currentValueIsEmpty && !wasReinitialized"
        color="primary"
        @click="handleConfirm"
      />
    </v-date-picker>
  </v-dialog>
</template>

<script>
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
export default {
  name: 'DateFilter',
  props: {
    value: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      modal: false,
      currentValue: [],
      wasReinitialized: false,
      valueBeforeChanges: null,
    };
  },
  computed: {
    currentLocale () {
      return this.$i18n && this.$i18n.locale
        ? this.$i18n.locale
        : 'en';
    },
    currentValueIsEmpty () {
      return Object.keys(this.currentValue).length === 0;
    },
    currentTitle () {
      if (this.currentValue.length >= 2) {
        return this.currentValue[0] < this.currentValue[1]
          ? `${this.formatDateForTitle(this.currentValue[0])} - ${this.formatDateForTitle(this.currentValue[1])}`
          : `${this.formatDateForTitle(this.currentValue[1])} - ${this.formatDateForTitle(this.currentValue[0])}`;
      } else if (this.currentValue.length === 1) {
        return `${this.formatDateForTitle(this.currentValue[0])}`;
      } else {
        return '-';
      }
    },
  },
  watch: {
    // When opening modal, make sure to keep the previous value in memory
    modal () {
      if (this.modal === true && !this.currentValueIsEmpty) {
        this.valueBeforeChanges = cloneDeep(this.currentValue);
      }
    },
    // Listen for global resets of all filters
    value () {
      if (this.value && this.value.length === 0) {
        this.currentValue = [];
      }
    },
  },
  methods: {
    getValue (index) {
      return this.value[index] || '';
    },
    closeModale () {
      this.modal = false;
    },
    handleInput (value) {
      this.currentValue = value;
      this.wasReinitialized = false;
    },
    handleCancel () {
      if (this.valueBeforeChanges !== null) {
        this.currentValue = this.valueBeforeChanges;
      }
      this.modal = false;
    },
    handleReset () {
      this.currentValue = [];
      this.wasReinitialized = true;
    },
    formatDateForTitle (dateString) {
      moment.locale(this.currentLocale);
      return moment(dateString).format('ll');
    },
    handleConfirm () {
      if (this.currentValueIsEmpty === false) {
        if (Array.isArray(this.currentValue) && this.currentValue.length) {
          // Re-order range
          if (new Date(this.currentValue[0]) > new Date(this.currentValue[1])) {
            this.currentValue = [this.currentValue[1], this.currentValue[0]];
          }
        }
        this.$emit('input', this.currentValue);
      } else {
        this.$emit('input', undefined);
      }
      this.valueBeforeChanges = null;
      this.closeModale();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-date-picker-title__date {
  @include rem(font-size, 22px);
}
</style>
